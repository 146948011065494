import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['selectedformtype','selectedModalityValue']

    connect() {
        const questionKeySdvVerified = this.element.getAttribute("data-question-key");
        const question = document.getElementById(questionKeySdvVerified); 
        if (question !== null) {
            const handleChange = () => {
                const selectedValue = question.value;
                if (selectedValue === 'true') {
                    const questionVariableWithoutSuffix = questionKeySdvVerified.replace(/_sdv_verified$/, '');
                    const commentVariableWithSuffix = questionVariableWithoutSuffix + "_sdv_comments";
                    const comment = document.getElementById(commentVariableWithSuffix);
                    comment.disabled = false;   
                }else if (selectedValue === 'false') {
                    const questionVariableWithoutSuffix = questionKeySdvVerified.replace(/_sdv_verified$/, '');
                    const commentVariableWithSuffix = questionVariableWithoutSuffix + "_sdv_comments";
                    const comment = document.getElementById(commentVariableWithSuffix);
                    comment.disabled = true;
                    comment.value = '';
                }
            };  
            question.addEventListener('change', handleChange);    
            handleChange();
        }
    }
    
    submit(){
        Swal.fire({
            icon: 'success',
            title: 'Successfully Done!',
            text: 'SDV verification has been updated successfully.',
            timer: 2000,
            showConfirmButton: false
        });    
    }
    
    zoomIn(event) {
        const fileUrl = event.target.getAttribute("data-file-url") || event.target.getAttribute("src");
        const fileType = fileUrl.split('.').pop().toLowerCase(); // Get the file extension
    
        if (fileType === "pdf") {
            // Open the PDF in a new tab
            window.open(fileUrl, "_blank");
        } else {
            // Handle image zoom
            const zoomedImage = document.getElementById("zoomed-image");
            zoomedImage.src = fileUrl;
    
            const modalOverlay = document.getElementById("modal-overlay");
            modalOverlay.style.display = "block";
    
            // Add click event listener to close the modal if clicked outside the zoomed image
            modalOverlay.addEventListener("click", function (e) {
                modalOverlay.style.display = "none";
            });
        }
    }

    dependentSelect() {
        const selectedformtype = $(this.selectedformtypeTarget);
        const selectedformtypeText = selectedformtype.find(':selected').text();
        const dependDropdown = document.getElementById('dependDropdown');
        const formVersionSelect = document.getElementById('sdv_export_form_version');
        if (selectedformtypeText === "Grading Form SDV" || selectedformtypeText === "Adjudication Form SDV" || selectedformtypeText === "Grading Questions SDV" || selectedformtypeText === "Adjudication Questions SDV" || selectedformtypeText === "Grading Files" || selectedformtypeText === "Adjudication Files") {
            dependDropdown.classList.remove('hidden');
            formVersionSelect.setAttribute('required', true);
        }else{
            dependDropdown.classList.add('hidden');
            formVersionSelect.removeAttribute('required');
        }
    }
    selectModalityForms(){
        const selectedModalityValue = $(this.selectedModalityValueTarget);
        const modality_id = selectedModalityValue.val();
        const studyId = document.getElementById('sdv_export_study_id').value;
        const Url = document.location.origin + `/studies/${studyId}/sdv_exports/get_grading_form`
    fetch(Url, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        method: 'POST',
        body: JSON.stringify({
            modality_id: modality_id
        })
    }).then(response => response.json())
    .then(data => {
        const formVersionSelect = document.getElementById('sdv_export_form_version');
        formVersionSelect.innerHTML = '';
    
        if (data && data.length > 0) {
            const ids = data.map(item => item.id);
            const names = data.map(item => item.form_title);
    
            const defaultOption = document.createElement('option');
            defaultOption.value = "";
            defaultOption.text = "Select Grading Form";
            formVersionSelect.appendChild(defaultOption);
    
            if (data.length === 1) {
                defaultOption.value = ids[0];
                defaultOption.text = names[0];
                defaultOption.selected = true;
                const dependDropdown = document.getElementById('dependDropdown');
                dependDropdown.classList.add('pointer-events-none');
            } else {
                const dependDropdown = document.getElementById('dependDropdown');
                dependDropdown.classList.remove('pointer-events-none');
                
                for (let i = 0; i < ids.length; i++) {
                    const option = document.createElement('option');
                    option.value = ids[i];
                    option.text = names[i];
                    formVersionSelect.appendChild(option);
                }
            }
        } else {
            const defaultOption = document.createElement('option');
            defaultOption.value = "";
            defaultOption.text = "Select Grading Form";
            formVersionSelect.appendChild(defaultOption);
        }
    });
    
    
    }
}