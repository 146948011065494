import { Controller } from "stimulus";
import NotificationModule from "./shared_modules/notification_module";
export default class extends Controller {
  static targets = ["container", "rowTemplate", "appendButton"];
  connect() {
    this.studyId = this.data.get("studyId");
    this.studyUpdate =
      document.location.origin + this.data.get("studyUpdateUrl") + ".json";
    this.additionalStudyUpdate =
      document.location.origin +
      this.data.get("additionalStudyUpdateUrl") +
      ".json";
    this.Url =
      document.location.origin +
      `/studies/${this.studyId}/grading_setup/delete_graders`;
    // initialize modules
    this.notifications = new NotificationModule(
      this.hasLoadingImageTarget ? this.loadingImageTarget : null
    );
  }

  checkallrows(event) {
    console.log("sdbfg");
    const furtherChildRow = event.target.closest(".checkAllRowsforDuplicate");
    const newparentGradingFormSelect = furtherChildRow.querySelector(
      "select[name*='study_modality_grading_form_id']"
    );
    newparentGradingFormSelect.addEventListener("change", () =>
      checkDuplicate(furtherChildRow)
    );
    function checkDuplicate(row) {
      const currentStudyModalityId = row.querySelector(
        "input[name*='study_modality_id']"
      ).value;
      const currentVisitId = row.querySelector("input[name*='visit_id']").value;
      const currentStudyModalityGradingFormId = row.querySelector(
        "select[name*='study_modality_grading_form_id']"
      ).value;
      const allRows = document.querySelectorAll(
        "[data-grading-setup-target='rowTemplate'], .furtherChildRow"
      );

      allRows.forEach((otherRow) => {
        if (otherRow !== row) {
          const otherStudyModalityGradingFormId = otherRow.querySelector(
            "select[name*='study_modality_grading_form_id']"
          ).value;
          const otherStudyModalityId = otherRow.querySelector(
            "input[name*='study_modality_id']"
          ).value;
          const otherVisitId = otherRow.querySelector(
            "input[name*='visit_id']"
          ).value;
          if (
            currentStudyModalityId === otherStudyModalityId &&
            currentVisitId === otherVisitId &&
            currentStudyModalityGradingFormId ===
              otherStudyModalityGradingFormId
          ) {
            showMessage(
              "This form is already selected. Please select another grading form."
            );
            row.querySelector(
              "select[name*='study_modality_grading_form_id']"
            ).value = "";
          }
        }
      });
    }

    function showMessage(message) {
      const modal = document.createElement("div");
      modal.className =
        " fixed transform  right-0  p-4 bg-red-600 text-white rounded z-50";
      modal.textContent = message;

      document.body.appendChild(modal);

      setTimeout(function () {
        modal.remove();
      }, 3000); // Remove the modal after 3 seconds (adjust as needed)
    }
  }

  appendRow(event) {
    function generateUUID() {
      // This function generates a UUID
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    }

    const rowTemplate = event.target.closest(
      "[data-grading-setup-target='rowTemplate']"
    );
    const currentStudyModalityId = rowTemplate.querySelector(
      "input[name*='study_modality_id']"
    ).value;
    const currentVisitId = rowTemplate.querySelector(
      "input[name*='visit_id']"
    ).value;
    const furtherChildRow = document.querySelectorAll(".furtherChildRow");

    // Rest of the code for cloning the row
    const clonedRow = rowTemplate.cloneNode(true);

    // Add a class to identify the appended rows
    clonedRow.classList.add("appended-row");

    // Generating a new UUID for the requirement ID
    const newRequirementId = generateUUID();

    // Creating a new hidden field with the generated UUID
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "requirements[requirements][]requirement[id]";
    hiddenField.value = newRequirementId;
    hiddenField.classList.add("form-control");

    // Finding the container for the hidden field and replacing the existing one with the new generated field
    const existingHiddenField = clonedRow.querySelector(
      '[name*="requirements[requirements][]requirement[id]"]'
    );
    existingHiddenField.parentNode.replaceChild(
      hiddenField,
      existingHiddenField
    );

    // Set default value for study_modality_grading_form_id
    const gradingFormSelect = clonedRow.querySelector(
      "select[name*='study_modality_grading_form_id']"
    );
    const eligibilityFormSelect = clonedRow.querySelector(
      "select[name*='study_modality_eligibility_qc_form_id']"
    );
    const qcFormSelect = clonedRow.querySelector(
      "select[name*='study_modality_qc_form_id']"
    );
    gradingFormSelect.value = "";
    gradingFormSelect.setAttribute("required", true);
    eligibilityFormSelect.value = "";
    qcFormSelect.value = "";
    const parentGradingFormSelect = rowTemplate.querySelector(
      "select[name*='study_modality_grading_form_id'], .furtherChildRow"
    );
    // Add change event listener to study_modality_grading_form_id
    gradingFormSelect.addEventListener("change", () =>
      checkDuplicateGradingForm(clonedRow)
    );
    parentGradingFormSelect.addEventListener("change", () =>
      checkDuplicateGradingForm(rowTemplate)
    );

    const deleteButton = clonedRow.querySelector(".delete-row-btn");
    deleteButton.addEventListener("click", () => deleteRow(clonedRow));

    const parentRow = rowTemplate.closest(".form-group");
    parentRow.insertAdjacentElement("afterend", clonedRow);

    // Toggle display for delete and append buttons on the newly appended row
    const deleteBtnContainer = clonedRow.querySelector(".delete-btn-container");
    const appendBtnContainer = clonedRow.querySelector(".append-btn-container");
    const hideOthersInputWhenAppend =
      clonedRow.querySelectorAll(".hideWhenAppend");
    const appendedInput = clonedRow.querySelectorAll(".appendedInput");
    deleteBtnContainer.style.display = "block";
    appendBtnContainer.style.display = "none";

    hideOthersInputWhenAppend.forEach((element) => {
      element.style.pointerEvents = "none";
      element.classList.remove("mt-8");
      element.classList.add("mt-2");
    });

    appendedInput.forEach((element) => {
      element.classList.remove("mt-8");
      element.classList.add("mt-2");
    });

    clonedRow.querySelectorAll(".hideable-label").forEach((label) => {
      label.style.display = "none";
    });

    function checkDuplicateGradingForm(row) {
      const currentStudyModalityId = row.querySelector(
        "input[name*='study_modality_id']"
      ).value;
      const currentVisitId = row.querySelector("input[name*='visit_id']").value;
      const currentStudyModalityGradingFormId = row.querySelector(
        "select[name*='study_modality_grading_form_id']"
      ).value;
      const allRows = document.querySelectorAll(
        "[data-grading-setup-target='rowTemplate'], .furtherChildRow"
      );
      // console.log(allRows);
      allRows.forEach((otherRow) => {
        if (otherRow !== row) {
          const otherStudyModalityGradingFormId = otherRow.querySelector(
            "select[name*='study_modality_grading_form_id']"
          ).value;
          const otherStudyModalityId = otherRow.querySelector(
            "input[name*='study_modality_id']"
          ).value;
          const otherVisitId = otherRow.querySelector(
            "input[name*='visit_id']"
          ).value;
          if (
            currentStudyModalityId === otherStudyModalityId &&
            currentVisitId === otherVisitId &&
            currentStudyModalityGradingFormId ===
              otherStudyModalityGradingFormId
          ) {
            showMessage(
              "This form is already selected. Please select another grading form."
            );
            row.querySelector(
              "select[name*='study_modality_grading_form_id']"
            ).value = "";
          }
        }
      });
    }

    function showMessage(message) {
      const modal = document.createElement("div");
      modal.className =
        " fixed transform  right-0  p-4 bg-red-600 text-white rounded z-50";
      modal.textContent = message;

      document.body.appendChild(modal);

      setTimeout(function () {
        modal.remove();
      }, 3000); // Remove the modal after 3 seconds (adjust as needed)
    }

    function deleteRow(row) {
      // Get the selected value before removing the row
      const gradingFormSelect = row.querySelector(
        "select[name*='study_modality_grading_form_id']"
      );
      const selectedValue = gradingFormSelect.value;

      // Remove the row
      const parentRow = row.closest(".form-group");
      if (parentRow) {
        row.remove();
        // Show the selected value in the parent dropdown
        const parentGradingFormSelect = rowTemplate.querySelector(
          "select[name*='study_modality_grading_form_id']"
        );
        const parentOptions =
          parentGradingFormSelect.querySelectorAll("option");
        parentOptions.forEach((option) => {
          if (option.value === selectedValue) {
            option.style.display = "block";
          }
        });
      } else {
        console.error("Parent row not found");
      }
    }
  }

  deleteRowFromDatabase(event) {
    this.rowId = event.currentTarget.id;
    this.studyModality = event.currentTarget.dataset.studymodality;
    this.visit = event.currentTarget.dataset.visit;
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this record?"
    );
    // Check if the user confirmed the deletion
    if (isConfirmed) {
      fetch(this.Url, {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": document.head
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        method: "POST", // Change the method to POST
        body: JSON.stringify({
          multipleGradingFormId: this.rowId,
          visit_id: this.visit,
          study_modality_id: this.studyModality,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          alert(data.message);
          if (data.status === "ok") {
            this.removeRowFromScreen(this.rowId);
          }
        });
    }
  }

  removeRowFromScreen(rowId) {
    // Implement logic to remove the row from the screen
    const rowElement = document.getElementById(rowId);
    if (rowElement) {
      // Assuming the parent element has the class 'form-group'
      const parentElement = rowElement.closest(".form-group");

      if (parentElement) {
        parentElement.remove();
      }
    }
  }

  change_study_settings(event) {
    // Access the modality and visit IDs from the data attributes
    const modalityId = event.target.dataset.modalityId;
    const visitId = event.target.dataset.visitId;
    const originalValue = event.target.dataset.originalValue;
    const visitStudyModalityId = event.target.dataset.visitStudyModalityId;
    // Get the value of the text box from where the event was triggered
    const inputValue = event.target.value;

    // Proceed only if the input value is greater than 1
    if (inputValue > 1) {
      // Use the notifications confirmAction() method for confirmation
      this.notifications
        .confirmAction(
          "Are you sure?",
          "By applying this change, all previous visits will be automatically locked.",
          "Yes, apply it!",
          "No, keep it"
        )
        .then((result) => {
          if (result.isConfirmed) {
            this.notifications.showLoader();

            fetch(this.studyUpdate, {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-CSRF-Token": document.head
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute("content"),
              },
              method: "PATCH",
              body: JSON.stringify({
                visit_id: visitId,
                modality_id: modalityId,
                input_value: inputValue, // Send the input value to the server if needed
                id: visitStudyModalityId,
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                this.notifications.hideLoader();
                this.notifications.success(
                  "Stacks and VisitStudyModality successfully updated!"
                );
              });
          } else {
            // Restore the original value if the user cancels
            event.target.value = originalValue;
          }
        });
    }
  }

  additional_forms_settings(event) {
    // Access the modality and visit IDs from the data attributes
    const modalityId = event.target.dataset.modalityId;
    const visitId = event.target.dataset.visitId;
    const multipleGradingFormId = event.target.dataset.multipleGradingFormId;
    // Get the value of the text box from where the event was triggered
    const inputValue = event.target.value;

    // Proceed only if the input value is greater than 1
    if (inputValue > 1) {
      // Use the notifications confirmAction() method for confirmation
      this.notifications
        .confirmAction(
          "Are you sure?",
          "By applying this change, all previous visits will be automatically locked.",
          "Yes, apply it!",
          "No, keep it"
        )
        .then((result) => {
          if (result.isConfirmed) {
            this.notifications.showLoader();

            fetch(this.additionalStudyUpdate, {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-CSRF-Token": document.head
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute("content"),
              },
              method: "PATCH",
              body: JSON.stringify({
                visit_id: visitId,
                modality_id: modalityId,
                id: multipleGradingFormId,
                input_value: inputValue, // Send the input value to the server if needed
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                this.notifications.hideLoader();
                this.notifications.success(
                  "Additional and Multiple Grading Forms successfully updated!"
                );
              });
          } else {
            // Restore the original value if the user cancels
            event.target.value = originalValue;
          }
        });
    }
  }
}
